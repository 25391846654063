import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Box,
  Backdrop,
  CircularProgress,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import useDataUsr from "../../hooks/useDataUsr";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import View from "../../utils/view/View";
import ReadData from "../../utils/read/Read";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateRangeIcon from "@mui/icons-material/DateRange";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment-timezone";
import MenuBookIcon from "@mui/icons-material/MenuBook";
const admin_service = require("../../helpers/admin_service");

const FormatoAsesorComercial = (props) => {
  const [formato, setFormato] = useState(null);
  const [cargando, setCargando] = useState(true);
  const { dataUsr, error, loading } = useDataUsr();

  useEffect(() => {
    if (dataUsr) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .getDataAuth(
          "/propuesta-in-house/view-asesor-comercial/" + props.id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos) => {
          setFormato(datos.data.response_database.result[0]);
          setCargando(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataUsr]);
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!formato ? (
        <Box>
          <Stack spacing={5}>
            <Typography textAlign="center" variant="h4">
              Formato de asesor comercial no generado
            </Typography>
            {dataUsr &&
              (dataUsr.permisos == "COMERCIAL" ||
                dataUsr.permisos == "REDES SOCIALES") && (
                <Button
                  startIcon={<DynamicFormIcon />}
                  href={"/in-house/DACA/" + props.id}
                  target="_blank"
                >
                  Click para llenar formato
                </Button>
              )}
          </Stack>
        </Box>
      ) : (
        <Box>
          <Stack spacing={5}>
            {" "}
            <View
              actionAfterDelete={() => {
                window.location.href = `/in-house`;
              }}
              configuration={{
                endpoint:
                  "/propuesta-in-house/view-asesor-comercial/" + props.id,
                key: "id",
                onUpdate:
                  dataUsr.permisos == "COMERCIAL" ||
                  dataUsr.permisos == "REDES SOCIALES"
                    ? `/in-house/DACA/${props.id}/update`
                    : null,
                withActions: false,
                name: "FORMATO ASESOR COMERCIAL",
                admin: true,
                properties: [
                  {
                    label: "Seleccione el tipo de institución",
                    field: "tipo_institucion",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label:
                      "Especifique el tipo de institución si seleccionó 'otro'",
                    field: "tipo_institucion_otro",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Ingrese el nombre de la institución",
                    field: "institucion",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Seleccione el título del solicitante",
                    field: "titulo",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Ingrese el nombre del solicitante",
                    field: "nombre",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Ingrese el cargo del solicitante",
                    field: "cargo",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Ingrese el área que realiza la solicitud",
                    field: "area_solicitud",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Cantidad de horas disponibles para la capacitación",
                    field: "cantidad_horas",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Seleccione la modalidad de preferencia",
                    field: "modalidad",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Ingrese el no. de participantes",
                    field: "participantes",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Seleccione el nivel de capacitación",
                    field: "nivel",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Requiere diploma de participación impreso",
                    field: "requiere_diploma",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Requiere certificado impreso",
                    field: "requiere_certificado",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Requiere material (presentaciones) impreso",
                    field: "requiere_material",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Ingrese el perfil de los participantes",
                    field: "perfil",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label:
                      "Alguna especificación sobre la experiencia del facilitador",
                    field: "especificacion",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Requiere equipo de cómputo",
                    field: "requiere_equipo_computo",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Especificación de equipo de cómputo",
                    field: "requiere_equipo_computo_especifico",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label:
                      "Posee un protocolo específico para la apertura de eventos",
                    field: "protocolo",
                    type: 1,
                    icon: "info",
                  },

                  {
                    label:
                      "Especificación de rotocolo para la apertura de eventos",
                    field: "protocolo_especifico",
                    type: 1,
                    icon: "info",
                  },
                ],
              }}
            />
            {formato.docentes != null && formato.docentes != [] && (
              <Stack spacing={2}>
                <Typography variant="h4">Docentes Sugeridos</Typography>
                {formato.docentes.map((actual, index) => (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography component="span">
                        Docente {index + 1}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={5}>
                        <View
                          actionAfterDelete={() => {
                            window.location.href = `/docente`;
                          }}
                          configuration={{
                            endpoint: "/docente/view/" + actual[0],
                            key: "UniqueID",
                            withActions: false,
                            name: "Docente Sugerido",
                            admin: true,
                            properties: [
                              {
                                label: "Nombres",
                                field: "Nombres",
                                type: 1,
                                icon: "info",
                              },
                              {
                                label: "Apellidos",
                                field: "Apellidos",
                                type: 1,
                                icon: "info",
                              },
                              {
                                label: "Fotografía",
                                field: "Foto",
                                type: 2,
                                icon: "image",
                              },
                              {
                                label: "Correo electrónico",
                                field: "Correo",
                                type: 1,
                                icon: "info",
                              },
                              {
                                label: "Telefono",
                                field: "Telefono",
                                type: 1,
                                icon: "info",
                              },
                            ],
                          }}
                        />
                        <ReadData
                          config={{
                            title: "Experiencia Docente",
                            endpoint: "/docente/get-exp/" + actual[0],
                            id: "UniqueID",
                            admin: true,
                            headers: [
                              {
                                id: "descripcion",
                                numeric: false,
                                disablePadding: true,
                                label: "Descripcion",
                              },
                              {
                                id: "Nombre",
                                numeric: false,
                                disablePadding: true,
                                label: "Tipo Experiencia",
                              },
                            ],
                          }}
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
            )}
            <Typography variant="h3"> Fechas tentativas para evento</Typography>
            <List>
              {formato &&
                formato.fechas_tentativas.map((actual, index) => (
                  <ListItem disablePadding key={index}>
                    <ListItemButton>
                      <ListItemIcon>
                        <DateRangeIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${moment(actual[0])
                          .locale("es-mx")
                          .format("DD/MM/Y h:mm:ss a")} - ${moment(actual[1])
                          .locale("es-mx")
                          .format("DD/MM/Y h:mm:ss a")}`}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
            <Typography variant="h3"> Temas Propuestos</Typography>
            <List>
              {formato &&
                formato.temas.map((actual, index) => (
                  <ListItem disablePadding key={index}>
                    <ListItemButton>
                      <ListItemIcon>
                        <MenuBookIcon />
                      </ListItemIcon>
                      <ListItemText primary={actual[0]} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default FormatoAsesorComercial;
