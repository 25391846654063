import React, { useEffect, useState } from "react";
import Formulario from "../../utils/forms/Formulario";
import { useParams } from "react-router-dom";
import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import Cookies from "js-cookie";

const admin_service = require("../../helpers/admin_service");
const UpdateDocenteDAFAC = () => {
  const { id, doc } = useParams();
  const [contador] = useState(0);
  const [formConfig, setFormConfig] = useState({});
  const [formatoFacilitador, setFormatoFacilitador] = useState(null);
  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .getDataAuth("/docente", jwt.TokenType, jwt.accessToken)
      .then((datos) => {
        admin_service
          .getDataAuth(
            "/propuesta-in-house/view-facilitador/" + id,
            jwt.TokenType,
            jwt.accessToken
          )
          .then((datos_propuesta) => {
            setFormatoFacilitador(
              datos_propuesta.data.response_database.result[0]
            );
            let propuesta =
              datos_propuesta.data.response_database.result[0].find(
                (actual) => actual.id == doc
              );
            if (propuesta == null) {
              window.location.href = "/not-found";
            }
            setFormConfig({
              buttonStyle: { backgroundColor: "white", width: "100%" },
              title: "Actualizar Docente a Propuesta",
              submitTitle: "Guardar Información",
              formConfig: [
                {
                  type: 3,
                  required: true,
                  value: propuesta.docente_id,
                  name: "docente",
                  label: "Seleccione el Docente",
                  title: "Docente",
                  lock: false,
                  options: datos.data.response.result.map((actual) => {
                    return {
                      label: `${actual.Nombres} ${actual.Apellidos}`,
                      value: actual.UniqueID,
                    };
                  }),
                },
              ],
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);

  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .putDataAuth(
          `/propuesta-in-house/update-formato/${id}/${doc}/${body.docente}`,
          jwt.TokenType,
          jwt.accessToken,
          {}
        )
        .then((response) => {
          resolve();
          window.location.href = "/in-house/view/" + id;
        })
        .catch((error) => {
          reject(
            `Ha ocurrido un error al registrar la propuesta, intente de nuevo.`
          );
        });
    });
  };
  return (
    <Box
      style={{
        paddingTop: "25px",
        paddingBottom: "25px",
        width: "100w",
        overflowY: "scroll",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Container>
        <Stack spacing={5}>
          <Paper elevation={5} style={{ backgroundColor: "transparent" }}>
            <Stack
              spacing={2}
              alignItems="center"
              justifyContent="center"
              padding={5}
            >
              <img
                src="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/Logotipos+Finales+ABG+2022-01.png"
                loading="lazy"
                style={{ width: "90%" }}
              />
              <Typography variant="h4">
                SEGUIMIENTO IN HOUSE FORMATO DAFAC
              </Typography>
            </Stack>
          </Paper>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Container>
    </Box>
  );
};

export default UpdateDocenteDAFAC;
