import React, { useEffect, useState } from "react";
import Formulario from "../../utils/forms/Formulario";
import { useParams } from "react-router-dom";
import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import Cookies from "js-cookie";

const admin_service = require("../../helpers/admin_service");
const UpdateDAFAC = () => {
  const { id, doc } = useParams();
  const [contador] = useState(0);
  const [formConfig, setFormConfig] = useState({});
  const [formatoFacilitador, setFormatoFacilitador] = useState(null);
  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .getDataAuth("/docente", jwt.TokenType, jwt.accessToken)
      .then((datos) => {
        admin_service
          .getDataAuth(
            "/propuesta-in-house/view-facilitador/" + id,
            jwt.TokenType,
            jwt.accessToken
          )
          .then((datos_propuesta) => {
            setFormatoFacilitador(
              datos_propuesta.data.response_database.result[0]
            );
            let propuesta =
              datos_propuesta.data.response_database.result[0].find(
                (actual) => actual.id == doc
              );
            if (propuesta == null) {
              window.location.href = "/not-found";
            }
            setFormConfig({
              buttonStyle: { backgroundColor: "white", width: "100%" },
              title: "Actualizar Propuesta Técnica",
              submitTitle: "Guardar Información",
              formConfig: [
                {
                  type: 3,
                  required: true,
                  value: propuesta.docente_id,
                  name: "docente",
                  label: "Seleccione el Docente",
                  title: "Docente",
                  lock: true,
                  options: datos.data.response.result.map((actual) => {
                    return {
                      label: `${actual.Nombres} ${actual.Apellidos}`,
                      value: actual.UniqueID,
                    };
                  }),
                },
                {
                  type: 1,
                  required: true,
                  value:
                    propuesta.formato != null ? propuesta.formato.titulo : "",
                  name: "titulo",
                  label: "Ingrese el título de la capacitación",
                  title: "Título de la capacitación",
                },
                {
                  type: 8,
                  required: true,
                  value:
                    propuesta.formato != null
                      ? propuesta.formato.descripcion
                      : "",
                  name: "descripcion",
                  label: "Ingrese la descripción de la propuesta",
                  title: "Descripción de la propuesta",
                },
                {
                  type: 8,
                  required: true,
                  value:
                    propuesta.formato != null
                      ? propuesta.formato.objetivo_general
                      : "",
                  name: "objetivo_general",
                  label: "Ingresa el objetivo general",
                  title:
                    "Objetivo general (recuerde que lleva verbo infinitivo)",
                },
                {
                  type: 17,
                  required: true,
                  value:
                    propuesta.formato != null &&
                    propuesta.formato.objetivos_especificos != null
                      ? propuesta.formato.objetivos_especificos
                      : [],
                  name: "objetivos_especificos",
                  title:
                    "Objetivos específicos (recuerde que lleva verbo infinitivo)",
                  fields: [
                    {
                      type: 1,
                      required: true,
                      value: "",
                      name: "objetivo_especifico",
                      label: "Ingrese el objetivo especifico",
                      title: "Objetivo específico",
                    },
                  ],
                },
                {
                  type: 8,
                  required: true,
                  value:
                    propuesta.formato != null
                      ? propuesta.formato.propuesta_metodologica
                      : "",
                  name: "propuesta_metodologica",
                  label: "Ingresa la propuesta metodologica",
                  title: "Propuesta metodológica",
                },

                {
                  type: 18,
                  required: true,
                  value:
                    propuesta.formato != null &&
                    propuesta.formato.sesiones != null
                      ? propuesta.formato.sesiones
                      : [],
                  name: "sesiones",
                  title: "Contenido de sesiones",
                  master: [
                    {
                      type: 7,
                      required: true,
                      value: "",
                      name: "cantidad_horas",
                      label: "Ingrese la cantidad de horas",
                      title: "Cantidad de horas",
                    },
                    {
                      type: 1,
                      required: false,
                      value: "",
                      name: "requiere_software",
                      label: "Colocar nombre y version",
                      title:
                        "Especificar si requiere algún software especializado",
                    },
                    {
                      type: 1,
                      required: false,
                      value: "",
                      name: "requiere_material_impreso",
                      label:
                        "Especificar si requiere algún material impreso (evento presencial)",
                      title:
                        "Especificar si requiere algún material impreso (evento presencial)",
                    },
                  ],
                  detail: [
                    {
                      type: 1,
                      required: true,
                      value: "",
                      name: "tema",
                      label: "Ingrese el tema a desarrollar",
                      title: "Tema a desarrollar",
                    },
                  ],
                },
                {
                  type: 8,
                  required: false,
                  value:
                    propuesta.formato != null
                      ? propuesta.formato.observaciones_adicionales
                      : "",
                  name: "observaciones_adicionales",
                  label: "Ingresa las observaciones adicionales",
                  title: "Observaciones Adicionales",
                },
              ],
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);

  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));

      /*propuesta.estado = "PROPUESTA ENVIADA";
      propuesta.institucion = body.institucion;*/
      let formato = formatoFacilitador;
      let doc_facilitador = formatoFacilitador.find(
        (actual) => actual.id == doc
      );
      doc_facilitador.formato = body;
      let indice = formatoFacilitador.findIndex((actual) => actual.id == doc);
      formato[indice] = doc_facilitador;
      admin_service
        .putDataAuth(
          "/propuesta-in-house/" + id,
          jwt.TokenType,
          jwt.accessToken,
          {
            propuesta_facilitador: formato,
          }
        )
        .then((response) => {
          resolve();
          window.location.href = "/in-house/view/" + id;
        })
        .catch((error) => {
          reject(
            `Ha ocurrido un error al registrar la propuesta, intente de nuevo.`
          );
        });
    });
  };
  return (
    <Box
      style={{
        paddingTop: "25px",
        paddingBottom: "25px",
        width: "100w",
        overflowY: "scroll",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Container>
        <Stack spacing={5}>
          <Paper elevation={5} style={{ backgroundColor: "transparent" }}>
            <Stack
              spacing={2}
              alignItems="center"
              justifyContent="center"
              padding={5}
            >
              <img
                src="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/Logotipos+Finales+ABG+2022-01.png"
                loading="lazy"
                style={{ width: "90%" }}
              />
              <Typography variant="h4">
                SEGUIMIENTO IN HOUSE FORMATO DAFAC
              </Typography>
            </Stack>
          </Paper>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Container>
    </Box>
  );
};

export default UpdateDAFAC;
