import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Components/dashboard/Dashboard";
import PersistLogin from "./context/PersistLogin";
import RequireAuth from "./validation/RequireAuth";
import Layout from "./Components/layout/Layout";
import ReadUsers from "./Components/users/Read";
import ViewUser from "./Components/users/View";
import UpdateUser from "./Components/users/Update";
import CreateUser from "./Components/users/Create";
import Profile from "./Components/users/Profile";
import UpdateProfile from "./Components/users/UpdateProfile";
import ReadAsesores from "./Components/asesor/Read";
import ViewAsesor from "./Components/asesor/View";
import UpdateAsesor from "./Components/asesor/Update";
import CreateAsesor from "./Components/asesor/Create";
import ReadDocente from "./Components/docente/Read";
import CreateDocente from "./Components/docente/Create";
import ReadTickets from "./Components/tickets/Read";
import CreateTicket from "./Components/tickets/Create";
import ViewTicket from "./Components/tickets/View";
import ReadFile from "./Components/tickets/ReadFile";
import CreateSeguimientoTicket from "./Components/tickets/seguimiento/Create";
import ViewSeguimientoTicket from "./Components/tickets/seguimiento/View";
import ReadFileSeguimiento from "./Components/tickets/seguimiento/ReadFile";
import UpdateTicket from "./Components/tickets/Update";
import ViewAsesorProfile from "./Components/asesor/Profile";
import UpdateAsesorProfile from "./Components/asesor/UpdateProfile";
import RequireHardToken from "./Components/secrets/SecretsLayout";
import ReadSecrets from "./Components/secrets/Read";
import Authenticate from "./Components/secrets/Authenticate";
import CreateSecret from "./Components/secrets/Create";
import ViewSecret from "./Components/secrets/View";
import UpdateSecret from "./Components/secrets/Update";
import ReadInventarioTI from "./Components/inventario-it/Read";
import CreateInventarioTI from "./Components/inventario-it/Create";
import ViewInventarioTI from "./Components/inventario-it/View";
import ViewFotoInventarioTI from "./Components/inventario-it/ViewFile";
import UploadFilesInventarioTI from "./Components/inventario-it/UploadFile";
import UpdateInventarioTI from "./Components/inventario-it/Update";
import ReadInHouse from "./Components/in-house/Read";
import ViewPropuesta from "./Components/in-house/View";
import DACA from "./Components/in-house/DACA";
import UpdateDACA from "./Components/in-house/UpdateDACA";
import ViewDocente from "./Components/docente/View";
import UpdateDocente from "./Components/docente/Update";
import IntegracionTecnicoEcono from "./Components/in-house/IntegracionTecnicoEcono";
import UpdateIntegracionTecnicoEcono from "./Components/in-house/UpdateIntegracionTecnicoEcono";
import UpdateDAFAC from "./Components/in-house/UpdateDAFAC";
import UpdateDocenteDAFAC from "./Components/in-house/UpdateDocenteDAFAC";
import AddDocenteDAFAC from "./Components/in-house/AddDocenteDAFAC";
export default function App() {
  return (
    <Router>
      <Routes>
        <Route element={<PersistLogin />}>
          <Route element={<Layout />}>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    "ADMINISTRADOR",
                    "DIRECCION-ACADEMICA",
                    "CONTROL-ACADEMICO",
                    "CONTABILIDAD",
                    "DISEÑO",
                    "COMERCIAL",
                    "REDES SOCIALES",
                  ]}
                />
              }
            >
              <Route path="/" element={<Dashboard />}></Route>
              <Route path="/profile" element={<Profile />}></Route>
              <Route path="/profile/update" element={<UpdateProfile />}></Route>
            </Route>
            <Route
              element={
                <RequireAuth allowedRoles={["COMERCIAL", "REDES SOCIALES"]} />
              }
            >
              <Route
                path="/asesor-profile"
                element={<ViewAsesorProfile />}
              ></Route>
              <Route
                path="/asesor-profile/update"
                element={<UpdateAsesorProfile />}
              ></Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={["ADMINISTRADOR"]} />}>
              <Route path="/users" element={<ReadUsers />}></Route>
              <Route path="/users/view/:id" element={<ViewUser />}></Route>
              <Route path="/users/update/:id" element={<UpdateUser />}></Route>
              <Route path="/users/create" element={<CreateUser />}></Route>
              <Route path="/asesor" element={<ReadAsesores />}></Route>
              <Route path="/asesor" element={<ReadAsesores />}></Route>
              <Route path="/asesor/view/:id" element={<ViewAsesor />}></Route>
              <Route
                path="/asesor/update/:id"
                element={<UpdateAsesor />}
              ></Route>
              <Route path="/asesor/create" element={<CreateAsesor />}></Route>
              <Route path="/docente" element={<ReadDocente />}></Route>
              <Route path="/docente/create" element={<CreateDocente />}></Route>
              <Route path="/docente/view/:id" element={<ViewDocente />}></Route>
              <Route
                path="/docente/update/:id"
                element={<UpdateDocente />}
              ></Route>
              <Route element={<RequireHardToken />}>
                <Route path="/secrets" element={<ReadSecrets />}></Route>
                <Route
                  path="/secrets/create"
                  element={<CreateSecret />}
                ></Route>
                <Route
                  path="/secrets/view/:id"
                  element={<ViewSecret />}
                ></Route>
                <Route
                  path="/secrets/update/:id"
                  element={<UpdateSecret />}
                ></Route>
              </Route>
              <Route
                path="/secrets/authenticate"
                element={<Authenticate />}
              ></Route>
              <Route
                path="/inventario-ti"
                element={<ReadInventarioTI />}
              ></Route>
              <Route
                path="/inventario-ti/create"
                element={<CreateInventarioTI />}
              ></Route>
              <Route
                path="/inventario-ti/view/:id"
                element={<ViewInventarioTI />}
              ></Route>
              <Route
                path="/inventario-ti/view-file/:inventario/:id"
                element={<ViewFotoInventarioTI />}
              ></Route>
              <Route
                path="/inventario-ti/upload-files/:id"
                element={<UploadFilesInventarioTI />}
              ></Route>
              <Route
                path="/inventario-ti/update/:id"
                element={<UpdateInventarioTI />}
              ></Route>
            </Route>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    "ADMINISTRADOR",
                    "CONTROL-ACADEMICO",
                    "COMERCIAL",
                    "REDES SOCIALES",
                    "DIRECCION-ACADEMICA",
                  ]}
                />
              }
            >
              <Route path="/ticket-soporte" element={<ReadTickets />}></Route>
              <Route
                path="/ticket-soporte/create"
                element={<CreateTicket />}
              ></Route>
              <Route
                path="/ticket-soporte/view/:id"
                element={<ViewTicket />}
              ></Route>
              <Route
                path="/ticket-soporte/update/:id"
                element={<UpdateTicket />}
              ></Route>
              <Route
                path="/ticket-soporte/view-file/:id/:url"
                element={<ReadFile />}
              ></Route>
              <Route
                path="/ticket-soporte/seguimiento/:id/create"
                element={<CreateSeguimientoTicket />}
              ></Route>
              <Route
                path="/ticket-soporte/seguimiento/:ticket_id/view/:id"
                element={<ViewSeguimientoTicket />}
              ></Route>
              <Route
                path="/ticket-soporte/seguimiento/:ticket_id/view-file/:id/:url"
                element={<ReadFileSeguimiento />}
              ></Route>
              <Route path="/in-house" element={<ReadInHouse />}></Route>
              <Route
                path="/in-house/view/:id"
                element={<ViewPropuesta />}
              ></Route>
              <Route path="/in-house/DACA/:id" element={<DACA />}></Route>
              <Route
                path="/in-house/DACA/:id/update"
                element={<UpdateDACA />}
              ></Route>
              <Route
                path="/in-house/DAFAC/:id/:doc/update"
                element={<UpdateDAFAC />}
              ></Route>
              <Route
                path="/in-house/DAFAC/:id/:doc/update-docente"
                element={<UpdateDocenteDAFAC />}
              ></Route>
              <Route
                path="/in-house/DAFAC/:id/add-docente"
                element={<AddDocenteDAFAC />}
              ></Route>
              <Route
                path="/in-house/integracion-tecnico-economica/:id"
                element={<IntegracionTecnicoEcono />}
              ></Route>
              <Route
                path="/in-house/integracion-tecnico-economica/:id/update"
                element={<UpdateIntegracionTecnicoEcono />}
              ></Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}
