import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Box,
  Backdrop,
  CircularProgress,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import useDataUsr from "../../hooks/useDataUsr";
import View from "../../utils/view/View";
import ReadData from "../../utils/read/Read";
import Formulario from "../../utils/forms/Formulario";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TerminalIcon from "@mui/icons-material/Terminal";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import InfoIcon from "@mui/icons-material/Info";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
const admin_service = require("../../helpers/admin_service");

const FormatoCoordinacionAcademica = (props) => {
  const [formato, setFormato] = useState(null);
  const [cargando, setCargando] = useState(true);
  const { dataUsr, error, loading } = useDataUsr();
  const [propuesta, setPropuesta] = useState(null);
  useEffect(() => {
    if (dataUsr) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .getDataAuth(
          "/propuesta-in-house/view-coordinacion-academica/" + props.id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos) => {
          admin_service
            .getDataAuth(
              "/propuesta-in-house/view/" + props.id,
              jwt.TokenType,
              jwt.accessToken
            )
            .then((datos_propuesta) => {
              setPropuesta(datos_propuesta.data.response_database.result[0]);
              setFormato(datos.data.response_database.result[0]);
              setCargando(false);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataUsr]);
  const sendSolicitud = () => {
    setCargando(true);
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .getDataAuth(
        "/propuesta-in-house/send-direccion-academica/" + props.id,
        jwt.TokenType,
        jwt.accessToken
      )
      .then((datos_propuesta) => {
        admin_service
          .putDataAuth(
            "/propuesta-in-house/" + props.id,
            jwt.TokenType,
            jwt.accessToken,
            { estado: "ENVIADO A DIRECCIÓN ACADÉMICA" }
          )
          .then((response_db) => {
            setCargando(false);
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!formato ? (
        <Box>
          <Stack spacing={5}>
            <Typography textAlign="center" variant="h4">
              Propuesta no generada
            </Typography>
            {dataUsr &&
              dataUsr.permisos == "CONTROL-ACADEMICO" &&
              propuesta != null &&
              propuesta.propuesta_facilitador != null && (
                <Button
                  startIcon={<DynamicFormIcon />}
                  href={"/in-house/integracion-tecnico-economica/" + props.id}
                  target="_blank"
                >
                  Click para llenar formato
                </Button>
              )}
            {dataUsr &&
              dataUsr.permisos == "CONTROL-ACADEMICO" &&
              propuesta != null &&
              propuesta.propuesta_facilitador == null && (
                <Typography variant="h5" textAlign="center">
                  Debe llenarse primero el formato de facilitador para generar
                  propuesta técnica y económica
                </Typography>
              )}
          </Stack>
        </Box>
      ) : (
        <Box>
          <Stack spacing={5}>
            {" "}
            <View
              actionAfterDelete={() => {
                window.location.href = `/in-house`;
              }}
              configuration={{
                endpoint:
                  "/propuesta-in-house/view-coordinacion-academica/" + props.id,
                onUpdate:
                  dataUsr.permisos == "CONTROL-ACADEMICO" ||
                  dataUsr.permisos == "DIRECCION-ACADEMICA"
                    ? `/in-house/integracion-tecnico-economica/${props.id}/update`
                    : null,
                key: "id",
                withActions: false,
                name: "INFORMACIÓN GENERAL",
                admin: true,
                properties: [
                  {
                    label:
                      "¿Es el mismo facilitador propuesto por el asesor comercial?",
                    field: "mismo_facilitador",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label:
                      "Si la respuesta es No, indicar el porqué no está en la propuesta",
                    field: "motivo_no_recomendacion",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Tipo de actividad que se requiere",
                    field: "tipo_actividad",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Título de la capacitación",
                    field: "titulo",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Cantidad de horas para la capacitación",
                    field: "cantidad_horas",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Dirigido a",
                    field: "dirigido_a",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "No. de participantes",
                    field: "participantes",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Objetivo general",
                    field: "objetivo_general",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Propuesta metodologica",
                    field: "propuesta_metodologica",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Observaciones adicionales",
                    field: "observaciones_adicionales",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Moneda para pagar honorarios",
                    field: "moneda",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Honorarios por hora pactados",
                    field: "honorarios",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label:
                      "Cantidad total de horas (para cálculo de honorarios)",
                    field: "horas_total",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Viáticos",
                    field: "viaticos",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Costo material impreso",
                    field: "impresiones",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Inversión Total",
                    field: "total",
                    type: 1,
                    icon: "info",
                  },
                ],
                extraActions:
                  dataUsr.permisos == "CONTROL-ACADEMICO"
                    ? [
                        {
                          configuration: {
                            type: 2,
                            icon: "mail",
                            title: "Enviar a dirección académica",
                            action: sendSolicitud,
                          },
                        },
                      ]
                    : null,
              }}
            />
            {formato != null &&
              formato.competencias_genericas != null &&
              formato.competencias_genericas.length > 0 && (
                <Box>
                  <Stack spacing={3}>
                    <Typography variant="h3">Competencias genéricas</Typography>
                    <List>
                      {formato.competencias_genericas.map((actual) => (
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary={actual[0]} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                </Box>
              )}
            {formato != null &&
              formato.competencias_transversales != null &&
              formato.competencias_transversales.length > 0 && (
                <Box>
                  <Stack spacing={3}>
                    <Typography variant="h3">
                      Competencias transversales
                    </Typography>
                    <List>
                      {formato.competencias_transversales.map((actual) => (
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary={actual[0]} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                </Box>
              )}
            {formato != null &&
              formato.competencias_tecnicas != null &&
              formato.competencias_tecnicas.length > 0 && (
                <Box>
                  <Stack spacing={3}>
                    <Typography variant="h3">Competencias técnicas</Typography>
                    <List>
                      {formato.competencias_tecnicas.map((actual) => (
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary={actual[0]} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                </Box>
              )}
            {formato != null &&
              formato.objetivos_especificos != null &&
              formato.objetivos_especificos.length > 0 && (
                <Box>
                  <Stack spacing={3}>
                    <Typography variant="h3">Objetivos Específicos</Typography>
                    <List>
                      {formato.objetivos_especificos.map((actual) => (
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary={actual[0]} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                </Box>
              )}
            {formato != null &&
              formato.sesiones != null &&
              formato.sesiones.length > 0 && (
                <Box>
                  <Stack spacing={3}>
                    <Typography variant="h3">Contenido de sesiones</Typography>
                    {formato.sesiones.map((actual, index) => (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Sesión #{index + 1}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="h5">
                            Detalles de sesion
                          </Typography>
                          <List>
                            <ListItem>
                              <ListItemButton>
                                <ListItemIcon>
                                  <AccessTimeIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Cantidad de horas"
                                  secondary={actual.master[0]}
                                />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemIcon>
                                  <TerminalIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Requiere software especializado"
                                  secondary={actual.master[1]}
                                />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemIcon>
                                  <LocalPrintshopIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Requiere material impreso"
                                  secondary={actual.master[2]}
                                />
                              </ListItemButton>
                            </ListItem>
                          </List>
                          <Typography variant="h5">
                            Temas a desarrollar
                          </Typography>
                          <List>
                            {actual.detail.map((current) => (
                              <ListItem disablePadding>
                                <ListItemButton>
                                  <ListItemIcon>
                                    <InfoIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={current[0]} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Stack>
                </Box>
              )}
            {formato.docente != null && (
              <View
                actionAfterDelete={() => {
                  window.location.href = `/docente`;
                }}
                configuration={{
                  endpoint: "/docente/view/" + formato.docente,
                  key: "UniqueID",
                  withActions: false,
                  name: "Docente Asignado",
                  admin: true,
                  properties: [
                    {
                      label: "Nombres",
                      field: "Nombres",
                      type: 1,
                      icon: "info",
                    },
                    {
                      label: "Apellidos",
                      field: "Apellidos",
                      type: 1,
                      icon: "info",
                    },
                    {
                      label: "Fotografía",
                      field: "Foto",
                      type: 2,
                      icon: "image",
                    },
                    {
                      label: "Correo electrónico",
                      field: "Correo",
                      type: 1,
                      icon: "info",
                    },
                    {
                      label: "Telefono",
                      field: "Telefono",
                      type: 1,
                      icon: "info",
                    },
                  ],
                }}
              />
            )}
            {formato.docente != null && (
              <ReadData
                config={{
                  title: "Experiencia Docente",
                  endpoint: "/docente/get-exp/" + formato.docente,
                  id: "UniqueID",
                  admin: true,
                  headers: [
                    {
                      id: "descripcion",
                      numeric: false,
                      disablePadding: true,
                      label: "Descripcion",
                    },
                    {
                      id: "Nombre",
                      numeric: false,
                      disablePadding: true,
                      label: "Tipo Experiencia",
                    },
                  ],
                }}
              />
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default FormatoCoordinacionAcademica;
