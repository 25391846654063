import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import DescriptionIcon from "@mui/icons-material/Description";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import SellIcon from "@mui/icons-material/Sell";
import LinkIcon from "@mui/icons-material/Link";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PersonIcon from "@mui/icons-material/Person";
import FeedIcon from "@mui/icons-material/Feed";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyIcon from "@mui/icons-material/Key";
import ChecklistIcon from "@mui/icons-material/Checklist";
import SaveIcon from "@mui/icons-material/Save";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DiscountIcon from "@mui/icons-material/Discount";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BookIcon from "@mui/icons-material/Book";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
const Icons = (props) => {
  return props.icon == "info" ? (
    <InfoIcon />
  ) : props.icon == "description" ? (
    <DescriptionIcon />
  ) : props.icon == "calendar" ? (
    <CalendarTodayIcon />
  ) : props.icon == "week" ? (
    <ViewWeekIcon />
  ) : props.icon == "timelapse" ? (
    <TimelapseIcon />
  ) : props.icon == "sell" ? (
    <SellIcon />
  ) : props.icon == "link" ? (
    <LinkIcon />
  ) : props.icon == "image" ? (
    <ImageIcon />
  ) : props.icon == "video" ? (
    <VideoCameraBackIcon />
  ) : props.icon == "sponsor" ? (
    <CurrencyExchangeIcon />
  ) : props.icon == "pdf" ? (
    <PictureAsPdfIcon />
  ) : props.icon == "person" ? (
    <PersonIcon />
  ) : props.icon == "feed" ? (
    <FeedIcon />
  ) : props.icon == "groups" ? (
    <GroupsIcon />
  ) : props.icon == "key" ? (
    <KeyIcon />
  ) : props.icon == "checklist" ? (
    <ChecklistIcon />
  ) : props.icon == "save" ? (
    <SaveIcon />
  ) : props.icon == "calendar-month" ? (
    <CalendarMonthIcon />
  ) : props.icon == "mail" ? (
    <MailOutlineIcon />
  ) : props.icon == "monetization" ? (
    <MonetizationOnIcon />
  ) : props.icon == "discount" ? (
    <DiscountIcon />
  ) : props.icon == "book" ? (
    <BookIcon />
  ) : props.icon == "check" ? (
    <CheckIcon />
  ) : props.icon == "trash" ? (
    <DeleteIcon />
  ) : (
    <AutoAwesomeIcon />
  );
};

export default Icons;
