import React, { useEffect, useState } from "react";
import Formulario from "../../utils/forms/Formulario";
import { useParams } from "react-router-dom";
import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import Cookies from "js-cookie";

const admin_service = require("../../helpers/admin_service");
const AddDocenteDAFAC = () => {
  const { id } = useParams();
  const [contador] = useState(0);
  const [formConfig, setFormConfig] = useState({});
  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .getDataAuth("/docente", jwt.TokenType, jwt.accessToken)
      .then((datos) => {
        setFormConfig({
          buttonStyle: { backgroundColor: "white", width: "100%" },
          title: "Añadir Docente a Propuesta Técnica",
          submitTitle: "Guardar Información",
          formConfig: [
            {
              type: 3,
              required: true,
              value: "",
              name: "docente",
              label: "Seleccione el Docente",
              title: "Docente",
              lock: false,
              options: datos.data.response.result.map((actual) => {
                return {
                  label: `${actual.Nombres} ${actual.Apellidos}`,
                  value: actual.UniqueID,
                };
              }),
            },
          ],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);

  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .putDataAuth(
          `/propuesta-in-house/add-docente-formato/${id}/${body.docente}`,
          jwt.TokenType,
          jwt.accessToken,
          {}
        )
        .then((response) => {
          resolve();
          window.location.href = "/in-house/view/" + id;
        })
        .catch((error) => {
          reject(
            `Ha ocurrido un error al registrar la propuesta, intente de nuevo.`
          );
        });
    });
  };
  return (
    <Box
      style={{
        paddingTop: "25px",
        paddingBottom: "25px",
        width: "100w",
        overflowY: "scroll",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Container>
        <Stack spacing={5}>
          <Paper elevation={5} style={{ backgroundColor: "transparent" }}>
            <Stack
              spacing={2}
              alignItems="center"
              justifyContent="center"
              padding={5}
            >
              <img
                src="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/Logotipos+Finales+ABG+2022-01.png"
                loading="lazy"
                style={{ width: "90%" }}
              />
              <Typography variant="h4">
                SEGUIMIENTO IN HOUSE FORMATO DAFAC
              </Typography>
            </Stack>
          </Paper>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Container>
    </Box>
  );
};

export default AddDocenteDAFAC;
